import PropertyCard from "@components/PropertyCard/PropertyCard"
import Animation from "@components/elements/Animation"
import Slider from "@components/elements/Slider/SSlider"
import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./SignaturePropertiesSlider.scss"
import useLimitSignatureProperties from "@hooks/dataHooks/useLimitSignatureProperties"
import GGFXImage from "@components/elements/GGFXImage"
import { parseHTML } from "@lib/utils"
import clsx from "clsx"
import CTALink from "@components/elements/CTALink"
import CurrencyPrice from "@components/CurrencyPrice/CurrencyPrice"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { Link } from "gatsby"
import {  getUserobjectData, isAuthenticated  } from "@starberry/myaccount-website-utils"

const SignaturePropertiesSlider = ({
  module,
  properties,
  strapiID,
  ggfx_results,
}) => {
  const { propertiesSing } = useLimitSignatureProperties(properties)

  // if (!module) return null
  const { description, cta_text } = module
  const { isMobile } = useDeviceMedia()
  setTimeout(() => {
    if (typeof window !== "undefined" && window?.location?.hash === "#singnature") {
      const element = document.getElementById(`singnature`)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, 2000)
  const [userObjects, setUserObjects] = useState({});
  useEffect(() => {
    if (isAuthenticated()) {
      const getUserObjects = async () => {
          try {
              const userObjects = await getUserobjectData()
              setUserObjects(userObjects.data.data)
          } catch (e) {
              console.log("e:", e)
          }
      }
      getUserObjects()
    }
  }, [])
  const CTA = (
    <Link className="button button-orange more-btn" to={'/buy/properties-for-sale/above-20000000/'}>
      {/* More {activeKey === KEYS.offplan ? "Projects" : "Properties"} */}
      View more properties
    </Link>
  )

  return (
    <Animation animateInType="up" className="singnature-slider-module-wrap" id="singnature">
      <Container className="singnature-slider-module-container">
        <Row>
          <Col lg="12" xl="3">
            <div className="content-section">
              <GGFXImage
                ImageSrc={module?.logo_image}
                altText="banner-bg"
                imagetransforms={ggfx_results}
                renderer="srcSet"
                imagename="page.featured_prop_slider_logo_image.logo_image"
                strapiID={strapiID}
                className="sign-img"
              />
              {/* <img src={img} /> */}
              <Row>
                <Col md="9" xl="12">
                  <div className="content">
                    {/* {parseHTML(description?.data?.description)} */}
                    <p><span><strong>Our high-net-worth and private client division</strong>. Signature property listings starting from <CurrencyPrice price="20,000,000" />.</span></p>
                  </div>
                </Col>
                <Col md="3" xl="12">
                  <div className="cta-section">
                    <CTALink
                      cta={cta_text?.cta}
                      // key={cta.id}
                      className={clsx("button", "button-orange")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg="12" xl="1" />
          <Col lg="12" xl="8">
            <div>
              <div className="singnature-slider-tab-section">
                <Slider size="sm">

                  {propertiesSing.length &&
                    propertiesSing
                      .slice(0, 6)
                      .map((property, index) => (
                        <PropertyCard
                          key={property.id}
                          address={property.display_address}
                          ammenities={property.accommodation_summary}
                          description={property.description}
                          bathrooms={property.bathroom}
                          bedrooms={property.bedroom}
                          building={property.building}
                          image={property.images?.[0]}
                          images={property?.images}
                          price={property.price}
                          size={property.floorarea_min}
                          index={index}
                          email={property?.crm_negotiator_id?.email}
                          crm_negotiator_id={property?.crm_negotiator_id?.id}
                          name={property?.crm_negotiator_id?.name}
                          crmID={property?.crm_id}
                          bitrix_id={property?.extra?.bitrix_id}
                          slug={property?.slug}
                          searchType={property?.search_type}
                          imagetransforms={property?.ggfx_results}
                          strapiID={property?.strapi_id}
                          department={property?.department}
                          slider
                          isSingnature
                          noContact
                          userObjects={userObjects}
                          link_to_employee={property?.link_to_employee}
                          imageCount={property?.images?.length}
                        />
                      ))}
                  <div className="property-card-wrapper more-box"><div className="property-card">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="66" height="56" viewBox="0 0 66 56" fill="none">
                        <path d="M40.5731 37.363V48.8286M12.6697 48.8286V1.22217H30.2206M57.4561 21.1479L62.15 22.3909V38.6246M6.84413 27.7712H1V48.8286H50.4432M21.037 48.8286V40.3129H32.2243V48.8286" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.5026 12.5389H16.8625V5.78564H25.3041M22.5026 23.9859H16.8625V17.2327H21.074M36.009 35.4145H16.8625V28.6612H25.3041M6.84404 34.6538H1.2782M1.2782 41.1658H6.84404" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round" />
                        <path opacity="0.6" d="M49.6457 37.3639L45.6754 31.4827M53.0594 35.0634L49.2004 29.3306M63.5418 54.2099C62.0204 55.2303 59.9611 54.8407 58.9221 53.3193L48.6253 38.0689L54.1355 34.3398L64.4323 49.5902C65.4527 51.1115 65.0631 53.1709 63.5418 54.2099Z" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round" />
                        <path opacity="0.6" d="M39.6637 32.7435C47.9736 32.7435 54.7101 26.007 54.7101 17.6972C54.7101 9.38734 47.9736 2.65088 39.6637 2.65088C31.3539 2.65088 24.6174 9.38734 24.6174 17.6972C24.6174 26.007 31.3539 32.7435 39.6637 32.7435Z" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <div className="price">Dive in the World of Luxury Estate</div>
                      <p>Discover Refined Living with Exceptional Exclusivity</p>
                      {CTA}
                    </div>
                  </div></div>
                </Slider>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Animation>
  )
}

export default SignaturePropertiesSlider
